import React from "react";

// Own components
import HeaderBreadcrumb from "../../components/Breadcrumb/HeaderBreadcrumb";
import StatCard from "../../components/StatCard/StatCard";
import AnualGraph from "../../components/StatisticalGraphs/AnualGraph/AnualGraph";
import LastTwoWeeksGraph from "../../components/StatisticalGraphs/LastTwoWeeksGraph/LastTwoWeeksGraph";
import { Columns } from "react-bulma-components";
import { FaUserCircle } from "react-icons/fa";

const AdminDashboard = () => {
  // return <div>admin Dashboard</div>;

  return (
    <>
      <HeaderBreadcrumb></HeaderBreadcrumb>
      <Columns>
        <Columns.Column sm={6} lg={3}>
          <StatCard
            title={"500"}
            subtitle="Nuevos usuarios"
            bigIcon={() => <FaUserCircle color="#3489aa" />}
          ></StatCard>
        </Columns.Column>
        <Columns.Column sm={6} lg={3}>
          <StatCard
            title={"500"}
            subtitle="Nuevos usuarios"
            bigIcon={() => <FaUserCircle color="#3489aa" />}
          ></StatCard>
        </Columns.Column>
        <Columns.Column sm={6} lg={3}>
          <StatCard
            title={"500"}
            subtitle="Nuevos usuarios"
            bigIcon={() => <FaUserCircle color="#3489aa" />}
          ></StatCard>
        </Columns.Column>
        <Columns.Column sm={6} lg={3}>
          <StatCard
            title={"500"}
            subtitle="Nuevos usuarios"
            bigIcon={() => <FaUserCircle color="#3489aa" />}
          ></StatCard>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column lg={12}>
          <LastTwoWeeksGraph />
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column lg={12}>
          <AnualGraph />
        </Columns.Column>
      </Columns>
    </>
  );
};

export default AdminDashboard;
