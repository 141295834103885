import React from "react";

const StatCard = ({ title, subtitle, bigIcon }) => {
  return (
    <div className="stats-card light-blue-card mb-4">
      <h3>{title}</h3>
      <p>{subtitle}</p>
      <i>{bigIcon()}</i>
    </div>
  );
};

export default StatCard;
