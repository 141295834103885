import React from "react";
import data from "./data";

const AnualGraph = () => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="card-header">
          <h5 className="card-title">Estadísticas anuales</h5>
        </div>
        <div id="chart"></div>
      </div>
    </div>
  );
};

export default AnualGraph;
