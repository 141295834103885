import React, { useContext, useEffect, useState } from "react";

import style from "./Profile.module.scss";

//api
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { modifyAccount } from "api/user";
import { cancelReserveApi } from "api/reservations";
import userStore from "contexts/userStore";
import { UIContext } from "contexts/UIContext";
//ui

import { IoMdHeart, IoIosNotifications } from "react-icons/io";
import { FaUserCircle, FaTicketAlt } from "react-icons/fa";
import { MdDelete, MdLocationOn, MdWatchLater } from "react-icons/md";
import { FaCalendar } from "react-icons/fa";

import Description from "../../components/Description/Description";
import Separate from "../../components/Atoms/Separate";
import Avatar from "../../components/Atoms/Avatar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "components/Modals/Modal";

import { Controller, useForm } from "react-hook-form";
import {
  Loader,
  Form,
  Section,
  Container,
  Columns,
  Card,
  Button,
} from "react-bulma-components";
import { change_password } from "../../api/auth";
import usePortal from "react-useportal";
import { useDeleteFavorite } from "hooks/api.hook";
import {
  useGetActiveReservation,
  useGetExpiredReservation,
  useGetFavorites,
  useGetNotifications,
  useProfileData,
} from "hooks";
import { useProfileSelectorTab } from "hooks/useProfileData";

const Profile = () => {
  const [photo, setPhoto] = useState("");
  const [isFavorites, setIsFavorites] = useState([]);
  const [error, setFormError] = useState("");

  const selectedTab = useProfileSelectorTab((state) => state.tab);
  const changeTab = useProfileSelectorTab((state) => state.changeTab);

  const { handleSubmit, errors, control, getValues } = useForm();
  const {
    openPortal: openPortalPassword,
    closePortal: closePortalPassword,
    isOpen: isOpenPassword,
    Portal: PortalPassword,
  } = usePortal({
    bindTo: document && document.getElementById("modal-portal"),
  });
  const {
    openPortal: openPortalProfile,
    closePortal: closePortalProfile,
    isOpen: isOpenProfile,
    Portal: PortalProfile,
  } = usePortal();
  const { deleteFavorite } = useDeleteFavorite();

  const user = userStore((state) => state.user);

  const { setUser } = userStore((state) => state);

  const profileData = useProfileData(user.id);
  console.log({ profileData });
  console.log({ user, profileData });
  console.log(profileData?.data);
  console.log(profileData?.data?.data);

  const activeReservation = useGetActiveReservation();
  console.log({ activeReservation });
  const expiredReservation = useGetExpiredReservation();
  console.log({ expiredReservation });
  const favorites = useGetFavorites();
  console.log({ favorites });
  const notifications = useGetNotifications();
  console.log({ notifications });

  const queryClient = useQueryClient();

  // console.log({ favorites });

  const dataMenu = [
    {
      icon: (
        <FaUserCircle
          color={`${selectedTab === "perfil" ? "white" : "#3489aa"} `}
        />
      ),
      title: "Perfil",
      slug: "#perfil",
    },
    {
      icon: (
        <IoMdHeart
          color={`${selectedTab === "favoritos" ? "white" : "#3489aa"} `}
        />
      ),
      title: "Favoritos",
      slug: "#favoritos",
    },
    {
      icon: (
        <FaTicketAlt
          color={`${selectedTab === "reservas" ? "white" : "#3489aa"} `}
        />
      ),
      title: "Reservas",
      slug: "#reservas",
    },
    {
      icon: (
        <IoIosNotifications
          color={`${selectedTab === "notificaciones" ? "white" : "#3489aa"} `}
        />
      ),
      title: "Notificaciones",
      slug: "#notificaciones",
    },
    {
      /*
      icon: <AiTwotoneSetting color="#3489aa" />,
      title: "Configuración",
      slug: "#configuracion"
    */
    },
  ];

  const reservationData = (reservation) => [
    {
      icon: <MdLocationOn color="#3489aa" />,
      title: `${reservation.address}`,
    },
    {
      icon: <FaCalendar color="#3489aa" />,
      title:
        reservation.date /* `${new Date(reservation.date).toDateString()}` */,
    },
    {
      icon: <MdWatchLater color="#3489aa" />,
      title: `${reservation.from_time} - ${reservation.to_time}`,
    },
  ];

  const Header = (props) => {
    const { title } = props;
    return (
      <Columns.Column
        mobile={{ size: 12 }}
        dekstop
        size={{ size: 12 }}
        className={style.headerSection}
      >
        <h2>{title}</h2>
      </Columns.Column>
    );
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: change_password,
    onError: (error) => {
      toast.error("Ha ocurrido un error intentalo de nuevo!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
    onSuccess: ({ data }) => {
      toast.success("Se ha cambiado la contraseña correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      closePortalPassword();
    },
  });

  const hanldeInvalidateUserProfile = () => {
    console.log({ user });

    queryClient.invalidateQueries({ queryKey: ["profileData", user.id] });
  };
  const { mutate: updateProfile, isPending: isLoadingProfile } = useMutation({
    mutationFn: modifyAccount,
    onError: (error) => {
      toast.error("Ha ocurrido un error intentalo de nuevo!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
    onSuccess: (data) => {
      toast.success("Se ha modificado correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      setTimeout(() => {
        hanldeInvalidateUserProfile();
      }, 2000);
      closePortalProfile();
      // profileData.refetch();
    },
  });

  const cancelReserve = useMutation({
    mutationFn: (id) => cancelReserveApi(id),
    onSuccess: async () => {
      toast.success("Se ha cancelado la reserva exitosamente!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    onError: (error) => {
      if (error.request.status === 400 || error.request.status === 500) {
        toast.error(
          "Ha ocurrido un error al cancelar reserva, intenta más tarde!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          }
        );
      }
    },
  });

  const onSubmit = async (body) => {
    await mutate(body);
  };

  const onSubmitUpdateProfile = async (body) => {
    const formData = new FormData();
    formData.append("first_name", body?.first_name);
    formData.append("last_name", body?.last_name);

    if (photo !== "") {
      formData.append("photo", photo);
    }
    const payload = {
      query: formData,
      id: user?.id,
    };
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    await updateProfile(payload);
  };

  const handleMenuClick = (section) => {
    // setActiveSection(section); // Actualiza la sección activa al seleccionar una opción

    changeTab(section);
  };

  const CardRow = ({ reservation }) => {
    return (
      <Columns.Column
        mobile={{ size: 12 }}
        tablet={{ size: 12 }}
        desktop={{ size: 12 }}
        className={style.columnCard}
      >
        <Card className={style.card}>
          <Columns className={style.cardContent}>
            {/* Imagen del espacio */}
            <Columns.Column size={3} className={style.columnImage}>
              <img
                src={reservation.logo}
                alt={reservation.edifice_name}
                className={style.imageReservation}
              />
            </Columns.Column>

            {/* Información */}
            <Columns.Column size={7} className={style.columnInfo}>
              <h3 className={style.edificeName}>{reservation.edifice_name}</h3>
              <ul className={style.infoList}>
                <li className={style.infoItem}>
                  <MdLocationOn className={style.icon} />
                  {reservation.address}
                </li>
                <li className={style.infoItem}>
                  <FaCalendar className={style.icon} />
                  {reservation.date}
                </li>
                <li className={style.infoItem}>
                  <MdWatchLater className={style.icon} />
                  {reservation.from_time} - {reservation.to_time}
                </li>
              </ul>
            </Columns.Column>

            {/* Botón */}
            <Columns.Column size={2} className={style.columnButton}>
              <Button
                className={style.reviewButton}
                color="primary"
                onClick={() => console.log("Dejar reseña para", reservation.id)}
              >
                Dejar reseña
              </Button>
            </Columns.Column>
          </Columns>
        </Card>
      </Columns.Column>
    );
  };

  const renderReservation = (reservation, label) =>
    reservation && reservation.data.results.length > 0 ? (
      reservation.data.results.map((reservation, i) => (
        <CardRow key={"CardRow-" + i} reservation={reservation} />
      ))
    ) : (
      <Columns.Column>
        <h5>{label}</h5>
      </Columns.Column>
    );

  const renderContent = () => {
    switch (selectedTab) {
      case "perfil":
        return (
          <>
            <Header title={"Perfil"} />
            <section id="perfil">
              <Columns className={style.infoProfile}>
                <Columns.Column
                  desktop={{ size: 6 }}
                  className={style.imageProfile}
                >
                  <Avatar
                    width={100}
                    height={100}
                    size={"is-128x128"}
                    rounded={true}
                    src={
                      profileData.data && profileData.data.data?.photo
                        ? profileData.data.data?.photo
                        : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                    }
                  />
                  <div className={style.infoUserMeta}>
                    <h2>
                      {profileData.data ? profileData.data.data?.username : ""}
                    </h2>
                    <p>
                      {profileData.data ? profileData.data.data?.email : ""}
                    </p>
                  </div>
                </Columns.Column>
                <Columns.Column className={style.editProfile}>
                  <Button
                    type="submit"
                    color="primary"
                    onClick={openPortalProfile}
                  >
                    Modificar Perfil
                  </Button>
                </Columns.Column>
                <Columns.Column className={style.editProfile}>
                  <Button
                    type="submit"
                    color="primary"
                    onClick={openPortalPassword}
                  >
                    Cambiar contraseña
                  </Button>
                </Columns.Column>
              </Columns>
            </section>
          </>
        );
      case "favoritos":
        return (
          <>
            <Header title={"Favoritos"} />
            <section id="favoritos">
              {favorites.isFetched &&
                favorites?.data?.data?.map((favorite, index) => (
                  <Card className={style.cardFavorite}>
                    <Columns
                      vCentered
                      key={"responseFavorite-" + index}
                      className={`${style.infoProfile} ${style.columnsFavorite}`}
                    >
                      <Columns.Column desktop={{ size: 2 }}>
                        <img
                          src={favorite.logo}
                          className={style.imageFavorite}
                        />
                      </Columns.Column>
                      <Columns.Column desktop={{ size: 9 }}>
                        <div className={style.infoUserMeta}>
                          <h2>Edificio: {favorite.edifice_name}</h2>
                          <h3>Place: {favorite.description}</h3>
                          <p>Tipo Place: {favorite.place_type}</p>
                        </div>
                      </Columns.Column>
                      <Columns.Column desktop={{ size: 1 }}>
                        <span
                          id="icon-id"
                          onClick={() => deleteFavorite(favorite.place_id)}
                        >
                          <MdDelete size="50px" color="grey" />
                        </span>
                      </Columns.Column>
                    </Columns>
                  </Card>
                ))}
            </section>
          </>
        );
      case "reservas":
        return (
          <>
            <Header title={"Reservas activas"} />
            <section id="reservas">
              {renderReservation(
                activeReservation.data,
                "No tienes reservas activas"
              )}
            </section>
            <Separate />
            <Header title={"Reservas pasadas"} />
            {renderReservation(
              expiredReservation.data,
              "No tienes reservas pasadas"
            )}
          </>
        );
      case "notificaciones":
        return (
          <>
            <Header title={"Notificaciones"} />
            <section id="notificaciones">
              <p>No hay notificaciones por el momento</p>
            </section>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Section className={style.sectionData}>
        <Container>
          <div>
            <Columns>
              <Columns.Column mobile={{ size: 12 }} desktop={{ size: 3 }}>
                <Description
                  isMenu={"Mi Cuenta"}
                  hasPaddingLeft={false}
                  onClick={handleMenuClick}
                  items={dataMenu}
                  activeSection={selectedTab}
                />
              </Columns.Column>
              {/* <Columns.Column
                mobile={{ size: 12 }}
                dekstop
                size={{ size: 9 }}
                className={style.columnData}
              >
                
                
                <Header title={"Favoritos"} />
                
                <Separate />
                <Header title={"Reservas pasadas"} />
                
                <Separate />
              </Columns.Column> */}
              <Columns.Column
                mobile={{ size: 12 }}
                dekstop
                size={{ size: 9 }}
                className={style.columnData}
              >
                {renderContent()}
              </Columns.Column>
            </Columns>
          </div>
        </Container>
        {isOpenPassword && (
          <PortalPassword
            closeOnOutsideClick={true}
            onClose={closePortalPassword}
            closeOnEsc={true}
          >
            <Modal isOpen={isOpenPassword} contentTitle="Cambiar contraseña">
              <div className="modal-content-login">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mediun-login">
                    <Form.Field>
                      <Form.Label htmlFor="new_password">
                        Ingrese Nueva contraseña
                      </Form.Label>
                      <Controller
                        as={Form.Input}
                        name="new_password"
                        type="password"
                        control={control}
                        rules={{
                          required: "Ingresa tu contraseña correctamente.",
                          minLength: {
                            value: 8,
                            message: "Debes ingresar 8 caracteres como mínimo.",
                          },
                        }}
                        aria-invalid={errors.new_password ? "true" : "false"}
                        defaultValue=""
                      />
                      {errors.new_password && (
                        <Form.Help color="danger">
                          {errors.new_password.message}
                        </Form.Help>
                      )}
                    </Form.Field>

                    <Form.Field>
                      <Form.Label htmlFor="new_password2">
                        Repita contraseña
                      </Form.Label>
                      <Controller
                        as={Form.Input}
                        name="new_password2"
                        type="password"
                        control={control}
                        rules={{
                          required: "Vuelve a ingresar la contraseña.",
                          minLength: {
                            value: 8,
                            message: "Debes ingresar 8 caracteres como mínimo.",
                          },
                          validate: (value) =>
                            value === getValues()["new_password"]
                              ? true
                              : "Las contraseñas no coinciden.",
                        }}
                        aria-invalid={errors.new_password2 ? "true" : "false"}
                        defaultValue=""
                      />
                      {errors.new_password2 && (
                        <Form.Help color="danger">
                          {errors.new_password2.message}
                        </Form.Help>
                      )}
                    </Form.Field>
                  </div>
                  <hr />
                  <Button
                    className="is-pulled-right"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        Accediendo... <Loader className="ml-4" />
                      </>
                    ) : (
                      "Aceptar"
                    )}
                  </Button>
                  <Button
                    className="is-pulled-left"
                    type="submit"
                    onClick={closePortalPassword}
                  >
                    Cerrar
                  </Button>
                </form>
              </div>
            </Modal>
          </PortalPassword>
        )}
        {isOpenProfile && (
          <PortalProfile>
            <Modal isOpen={isOpenProfile} contentTitle="Modificar perfil">
              <div className="modal-content-login">
                <form onSubmit={handleSubmit(onSubmitUpdateProfile)}>
                  <div className="mediun-login">
                    <Form.Field>
                      <Form.Control>
                        <Controller
                          name="photo"
                          control={control}
                          rules={{ required: false }}
                          defaultValue={[]}
                          render={(props) => {
                            return (
                              <div className={"file-input"}>
                                <input
                                  id="photo"
                                  type="file"
                                  name="photo"
                                  className={style.input_file_photo}
                                  accept=".png, .jpeg"
                                  // {...props}
                                  // value={props.value.filename}
                                  onChange={(event) => {
                                    setPhoto(event.currentTarget.files[0]);
                                    return props.onChange(
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </Form.Control>
                      {photo !== "" ? (
                        <label className={style.label_signup} htmlFor="photo">
                          <Avatar
                            width="6rem"
                            height="6rem"
                            rounded={true}
                            src={URL.createObjectURL(photo)}
                          />
                          <p className={style.file_name}></p>
                        </label>
                      ) : (
                        <label className={style.label_signup} htmlFor="photo">
                          <Avatar
                            width="6rem"
                            height="6rem"
                            rounded={true}
                            src={
                              profileData.data && profileData.data.data?.photo
                                ? profileData.data.data.photo
                                : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                            }
                          />
                          {/* <p className={style.file_name}></p> */}
                        </label>
                      )}
                      {errors.photo && (
                        <Form.Help color="danger">
                          Ingresa la foto correctamente.
                        </Form.Help>
                      )}
                    </Form.Field>

                    <Form.Field>
                      <Form.Label htmlFor="first_name">Nombres</Form.Label>
                      <Form.Control>
                        <Controller
                          as={Form.Input}
                          name="first_name"
                          control={control}
                          rules={{ required: false }}
                          aria-invalid={errors.first_name ? "true" : "false"}
                          defaultValue={
                            profileData.data &&
                            profileData.data?.data?.first_name
                              ? profileData.data.data.first_name
                              : " "
                          }
                        />
                      </Form.Control>
                      {errors.first_name && (
                        <Form.Help color="danger">Error en el nombre</Form.Help>
                      )}
                    </Form.Field>
                    <Form.Field>
                      <Form.Label htmlFor="last_name">Apellidos</Form.Label>
                      <Form.Control>
                        <Controller
                          as={Form.Input}
                          name="last_name"
                          control={control}
                          rules={{ required: false }}
                          aria-invalid={errors.last_name ? "true" : "false"}
                          defaultValue={
                            profileData.data && profileData.data.data?.last_name
                              ? profileData.data.data?.last_name
                              : ""
                          }
                        />
                      </Form.Control>
                      {errors.last_name && (
                        <Form.Help color="danger">
                          Error en el apellido.
                        </Form.Help>
                      )}
                    </Form.Field>
                  </div>
                  <hr />
                  <Button
                    className="is-pulled-right"
                    color="primary"
                    type="submit"
                    disabled={isLoadingProfile}
                  >
                    {isLoadingProfile ? (
                      <>
                        Accediendo... <Loader className="ml-4" />
                      </>
                    ) : (
                      "Aceptar"
                    )}
                  </Button>
                  <Button
                    className="is-pulled-left"
                    type="submit"
                    onClick={closePortalProfile}
                  >
                    Cerrar
                  </Button>
                </form>
              </div>
            </Modal>
          </PortalProfile>
        )}
        <ToastContainer />
      </Section>
    </>
  );
};

export default Profile;
