// AdminLogin.jsx
import React from "react";
import LoginForm from "./LoginForm.Component";
import userStore from "contexts/userStore";
import LoginLayout from "layouts/LoginLayout"; // Reutilizamos el LoginLayout
import { useHistory, useLocation } from "react-router-dom";

const AdminLogin = () => {
  const { setUserAndToken } = userStore((state) => state);
  const history = useHistory();
  const location = useLocation();

  const goToAdminDashboard = () => {
    const fromPage = location.state?.fromPage || "/admin/dashboard";
    history.replace(fromPage);
  };

  return (
    <LoginLayout>
      {" "}
      {/* Usamos LoginLayout para conservar el estilo */}
      <LoginForm
        role="tabpanel"
        tabIndex="0"
        aria-labelledby="admin-login"
        setUserData={setUserAndToken}
        goToHome={goToAdminDashboard}
      />
    </LoginLayout>
  );
};

export default AdminLogin;
