import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";
import style from "./DefaultLayout.module.scss";

const DefaultLayout = (props) => (
  <div className={style.layoutContainer}>
    <NavBar />
    <main className={style.mainContent}>{props.children}</main>
    <Footer />
  </div>
);
export default DefaultLayout;
