import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import { UserContext } from "../contexts/UserContext";

//layouts
import LoginLayout from "../layouts/LoginLayout";
import DefaultLayout from "../layouts/DefaultLayout";
//views
import Login from "../pages/Login/Login";
import Home from "../pages/Home/Home.jsx";
import Search from "../pages/Search/index.jsx";
import DetailEdifice from "../pages/DetailEdifice/DetailEdifice.jsx";
// import DetailZone from "../pages/DetailZone/DetailZone";
import FourHundredFour from "../pages/FourHundredFour/FourHundredFour";
import DetailSpace from "../pages/DetailSpace/DetailSpace.jsx";
import Profile from "../pages/Profile/Profile";

//assets
import userStore from "contexts/userStore";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import RecoverPassword from "pages/Login/RecoverForm.Component";

import AuthForm from "../pages/Login/AuthFormWrapper.Component";
import { useQueryClient } from "@tanstack/react-query";
import { fetchInitialParams } from "hooks/useGetInitialParams";
import AdminDashboard from "pages/AdminDashboard/AdminDashboard";
import AdminLogin from "pages/Login/AdminLogin";

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isLogged ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ) : (
          <LoginLayout>
            <Component {...props} />
          </LoginLayout>
        )
      }
    />
  );
};

const HomeRoute = ({ component: Component, isLogged, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} isLogged={isLogged} />
        </DefaultLayout>
      )}
    />
  );
};
const AdminRoute = ({ component: Component, isLogged, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <DefaultLayout>
            <Component {...props} isLogged={isLogged} />
          </DefaultLayout>
        ) : (
          <Redirect to="/login-admin" /> // Redirige a /login-admin si no está autenticado
        )
      }
    />
  );
};

const RecoverPasswordRoute = ({ component: Component, isLogged, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthForm>
          <Component {...props} isLogged={isLogged} />
        </AuthForm>
      )}
    />
  );
};

const AdminRouter = () => {
  const isUserLogged = userStore((store) => store.isLogged)();

  return (
    <Switch>
      <Route
        exact
        path="/login-admin"
        render={(props) =>
          isUserLogged ? (
            <Redirect to="/admin/dashboard" />
          ) : (
            <AdminLogin {...props} />
          )
        }
      />
      <AdminRoute
        isLogged={isUserLogged}
        exact
        path="/admin/dashboard"
        component={AdminDashboard}
      />
      {/* Otras rutas de administración pueden ir aquí */}
      <Route component={FourHundredFour} />
    </Switch>
  );
};

const MainRoute = () => {
  const isUserLogged = userStore((store) => store.isLogged)();
  const queryClient = useQueryClient();

  const prefetchData = () => {
    queryClient.prefetchQuery({
      queryKey: ["initialParams"],
      queryFn: fetchInitialParams,
      retry: 1,
      staleTime: 1000 * 60 * 60 * 24,
    });
  };

  useEffect(() => {
    prefetchData();
  }, []);

  return (
    <Router>
      <Switch>
        <LoginRoute
          isLogged={isUserLogged}
          exact
          path="/login/"
          component={Login}
        />
        <LoginRoute
          isLogged={isUserLogged}
          exact
          path="/recover-password/:uuid/:token"
          component={ChangePassword}
        />

        <HomeRoute isLogged={isUserLogged} exact path="/" component={Home} />
        <HomeRoute
          isLogged={isUserLogged}
          exact
          path="/busqueda/:query"
          component={Search}
        />
        <HomeRoute exact path="/perfil" component={Profile} />
        <HomeRoute exact path="/edificio/:slug" component={DetailEdifice} />
        <HomeRoute exact path="/espacio/:slug" component={DetailSpace} />
        <RecoverPasswordRoute
          exact
          path="/forgot-password"
          component={RecoverPassword}
        />

        {/* Rutas de administrador */}
        <Route exact path="/login-admin" component={AdminLogin} />
        <Route path="/admin" component={AdminRouter} />

        <Route component={FourHundredFour} />
      </Switch>
    </Router>
  );
};

export default MainRoute;
