import React from "react";
// import ReactEcharts from "echarts-for-react";
import data from "./data";

const LastTwoWeeksGraph = () => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="card-header">
          <h5 className="card-title">Últimas dos semanas</h5>
        </div>
      </div>
    </div>
  );
};

export default LastTwoWeeksGraph;
