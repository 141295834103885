// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { onMessage, getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCytXVenkx9jGEUsQd3aESyaN6Q8fe04QQ",
  authDomain: "espacios-47bf3.firebaseapp.com",
  projectId: "espacios-47bf3",
  storageBucket: "espacios-47bf3.appspot.com",
  messagingSenderId: "400480063042",
  appId: "1:400480063042:web:ed4b822bdaf89960a627db",
  measurementId: "G-JGQ2DP7J0Q",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { messaging, onMessage };
