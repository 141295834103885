import React from "react";
//import styles from './HeaderBreadcrumb.module.css';

const HeaderBreadcrumb = ({ currentSite }) => {
  return (
    <div className="main-content-header">
      <ul>
        <h1>Dashboard</h1>
        <li to="/administrador/dashboard/">Dashboard</li>
        <li active>{currentSite}</li>
      </ul>
    </div>
  );
};

export default HeaderBreadcrumb;
